import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react"
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Home, EmptyWalletTime, ArchiveBox, ClipboardTick, Location, Save2, Bootstrap, Setting, LoginCurve, LocationTick, Profile2User, Activity, User, ChartSquare } from 'iconsax-react';
import { withTranslation } from "react-i18next";
import { get, post } from "../../helpers/api_helper"
import SiderBarStoreDropDown from "./SiderBarStoreDropDown";
import config from "./../../config";
const SidebarContent = props => {
  const ref = useRef();
  const headers = {"Content-Type": "application/json","Authorization": `Bearer ${localStorage.getItem("token")}`,};

  const [carriers, setCarriers] = useState([]);
  const [show, setShow] = useState(true);
  const [stores, setStores] = useState([]);

  const retrieveStores = async () => {
    try {
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id
      };
      post('/user/getStores', body, { headers }).then((response) => {
        // console.log("stores in nav", response);
        let arr = [];
        arr.push({
          id: 0,
          store_id: null,
          store_name: "Manual Store",
          last_synced: null,
          store_type: "store",
        })
        for (let i = 0; i < response.data.length; i++) {
          arr.push({
            id: response.data[i].id,
            store_id: response.data[i].id,
            store_name: response.data[i].store_name,
            last_synced: response.data[i].last_synced,
            store_type: response.data[i].store_type,
          })
        }

        // console.log(arr)
        setStores(arr);
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    const pathName = props.location.pathname;
    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();

  }, [props.location.pathname]);

  useEffect(() => {
    if (document.title == `Awaiting Shipment | ${config.APP_DETAILS.APP_NAME}` || document.title == `Cancelled Orders | ${config.APP_DETAILS.APP_NAME}` || document.title == `On Hold Orders | ${config.APP_DETAILS.APP_NAME}`) {
      // document.getElementById("ord").click();
      // document.getElementById("order-li").classList.add("mm-active");
      // document.getElementById("order-a").classList.remove("mm-collapsed");
      // document.getElementById("order-ul").classList.remove("mm-collapsed");
      // document.getElementById("order-ul").classList.add("mm-show");
    }
  }, [])

  useEffect(() => {
    ref.current.recalculate();
  });

  useEffect(() => {
    setInterval(() => {
      setShow(document.body.classList.contains('right-bar-enabled') ? document.body.classList.contains('vertical-collpsed') ? false : true : true);
      // console.log("show",show);
      if (document.getElementsByClassName("active-page").length > 0) {
        if (document.body.classList.contains('right-bar-enabled')) {
          if (document.body.classList.contains('vertical-collpsed')) {
            document.getElementsByClassName("active-page")[0].style.top = "15px";
          }
          else {
            document.getElementsByClassName("active-page")[0].style.top = "10px";
          }
        }
        else {
          document.getElementsByClassName("active-page")[0].style.top = "10px";
        }
      }

    }, 100)

    retrieveStores();
  }, [])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  function isOrderPage() {
    if (document.title == `On Hold Orders | ${config.APP_DETAILS.APP_NAME}` || document.title == `Awaiting Shipment | ${config.APP_DETAILS.APP_NAME}` || document.title == `Cancelled Orders | ${config.APP_DETAILS.APP_NAME}`) {
      return true;
    }

    return true;
  }

  const handleClick = (url) => {
    props.history.push(url);
  };
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu" style={{ padding: "10px 0px 30px 0px", marginBottom: "30px" }}>
          <ul className="metismenu list-unstyled" id="side-menu" style={{ position: "relative", height: "100%" }}>
            {/* {JSON.parse(localStorage.getItem("authUser")).role_id == 2 ? <SiderBarStoreDropDown props={props} /> : ""} */}
            <li className="menu-title">{props.t("Menu")} </li>


            {
              JSON.parse(localStorage.getItem("authUser")).role_id == 1 ?
                <>
                  <li>
                    <Link to="/dashboard" >
                      <i><Home size={20} /></i>
                      <span>{props.t("Dashboard")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/users" >
                      <i className="bx bxs-group"></i>
                      <span>{props.t("Users")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/subscribers" >
                      <i className="bx bx-group"></i>
                      <span>{props.t("Subscribers")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/carriers" >
                      <i className="bx bxs-envelope"></i>
                      <span>{props.t("Carriers")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/services" >
                      <i className="bx bxs-label"></i>
                      <span>{props.t("Services")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/carrier-ids" >
                      <i className="bx bx-list-ul"></i>
                      <span>{props.t("Carrier IDs")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/tickets" >
                      <i className="bx bxs-bookmarks"></i>
                      <span>{props.t("Tickets")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/user/orders" >
                      <i className="bx bxs-box"></i>
                      <span>{props.t("Orders")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-x"></i>
                      <span>{props.t("Cancellation")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/cancellation-requests" >
                          <span>{props.t("Requests")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/cancellation-approved" >
                          <span>{props.t("Approved")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/cancellation-rejected">
                          <span>{props.t("Rejected")}</span>
                        </Link>
                      </li>
                      {/*<li>*/}
                      {/*  <Link to="/easypost-refund-status">*/}
                      {/*    <span>{props.t("Easypost Refund Status")}</span>*/}
                      {/*  </Link>*/}
                      {/*</li>*/}
                    </ul>
                  </li>
                  <li>
                    <Link to="/duplicated-tracking-orders" >
                      <i className="bx bx-duplicate"></i>
                      <span>{props.t("Duplicate Tracking Orders")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/user/orders-tracking" >
                      <i className="bx bx-map"></i>
                      <span>{props.t("Orders Tracking")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-clipboard"></i>
                      <span>{props.t("Reports")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/stores-connections" >
                          <span>{props.t("Stores Connections")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/running-schedulers" >
                          <span>{props.t("Running Schedulers")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/completed-schedulers" >
                          <span>{props.t("Completed Schedulers")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/top-label-generators" >
                          <span>{props.t("Top Label Generators")}</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-money"></i>
                      <span>{props.t("Payments")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/add-payment-method" >
                          <span>{props.t("Add Payment Method")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/payment-methods" >
                          <span>{props.t("Payment Methods")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/payment-requests" >
                          <span>{props.t("Payment Requests")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/plaid-payments" >
                          <span>{props.t("Plaid Payments")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/coinbase/payments" >
                          <span>{props.t("Coinbase Payments")}</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow ">
                      <i className="bx bx-group"></i>
                      <span>{props.t("Reference")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/add-user" >
                          <i className="bx bx-user-plus"></i>
                          <span>{props.t("Add User")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/referral" >
                          <i className="bx bx-list-ul"></i>
                          <span>{props.t("Referral")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/add-payment" >
                          <i className="bx bx-wallet"></i>
                          <span>{props.t("Add Payment")}</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow ">
                      <i className="bx bx-code-curly"></i>
                      <span>{props.t("Logs")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/order-errors" >
                          <i className="bx bxs-x-circle"></i>
                          <span>{props.t("Order Process Errors")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/order-success" >
                          <i className="bx bx-check-circle"></i>
                          <span>{props.t("Order Process Success")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/api-order-logs" >
                          <i className="bx bx-code-alt"></i>
                          <span>{props.t("Api Order Logs")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/google-address-validation-logs" >
                          <i className="bx bx-map"></i>
                          <span>{props.t("Address Validations")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/tracking-creation-logs" >
                          <i className="bx bx-analyse"></i>
                          <span>{props.t("Tracking Creations")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/tracking-webhook" >
                          <i className="bx bx-git-pull-request"></i>
                          <span>{props.t("Tracking Webhook")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/carrier-id-logs" >
                          <i className="bx bx-list-ul"></i>
                          <span>{props.t("Carrier Logs")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/user-wallet-logs" >
                          <i className="bx bx-wallet"></i>
                          <span>{props.t("User Wallet Logs")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/insured-order-logs" >
                          <i className="bx bx-wallet"></i>
                          <span>{props.t("Insured Order Logs")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/easypost-webhook-logs" >
                          <i className="bx bx-list-ol"></i>
                          <span>{props.t("Easypost Webhook Logs")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/card-payment-logs" >
                          <i className="bx bx-credit-card"></i>
                          <span>{props.t("Card Payment Logs")}</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow ">
                      <i className="bx bx-share-alt"></i>
                      <span>{props.t("In Process")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/dhl/orders" >
                          <i className="bx bx-clipboard"></i>
                          <span>{props.t("DHL Orders")}</span>
                        </Link>
                      </li>
                      {/*<li>*/}
                      {/*  <Link to="/usps/orders" >*/}
                      {/*    <i className="bx bx-clipboard"></i>*/}
                      {/*    <span>{props.t("USPS Orders")}</span>*/}
                      {/*  </Link>*/}
                      {/*</li>*/}
                    </ul>
                  </li>
                  <li>
                    <Link to="/discounts" >
                      <i className="bx bxs-discount"></i>
                      <span>{props.t("Discounts")}</span>
                    </Link>
                  </li>

                  {/* <li>
                    <Link to="/coinbase/payments" >
                      <i className="bx bx-money"></i>
                      <span>{props.t("Coinbase Payments")}</span>
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/api/settings" >
                      <i className="bx bxs-key"></i>
                      <span>{props.t("Api Settings")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/table-information" >
                      <i className="bx bx-table"></i>
                      <span>{props.t("Table's Information")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/download-pdf" >
                      <i className="bx bx-file"></i>
                      <span>{props.t("Download Pdf")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/send-notification" >
                      <i className="bx bx-send"></i>
                      <span>{props.t("Send Notification")}</span>
                    </Link>
                  </li>
                </>
                : (JSON.parse(localStorage.getItem("authUser")).role_id === 2 ?
                  <>
                    <li>
                      <span className={document.title == `Dashboard | ${config.APP_DETAILS.APP_NAME}` ? `active-page` : ""}></span>
                      <Link to="/dashboard" >
                        <i><ChartSquare size={20} /></i>
                        <span>{props.t("Insights")}</span>
                      </Link>
                    </li>
                    {/* AWAITING SHIPMENT  */}
                    <li onClick={() => handleClick('/orders/awaiting-shipment')} id="order-li" className="mm-active">
                      <Link to="/orders/awaiting-shipment" className="has-arrow " style={{ color: (isOrderPage() ? "white" : "") }} id="order-a">
                        <i style={{ color: (isOrderPage() ? "white" : "") }}><ArchiveBox size={20} /></i>
                        <span style={{ color: (isOrderPage() ? "white" : "") }}>{props.t("Awaiting Shipment")}</span>
                      </Link>
                      <ul className="sub-menu" id="order-ul">
                        <SiderBarStoreDropDown props={props} />
                      </ul>
                    </li>
                    {/* CANCELLED ORDERS  */}
                    <li onClick={() => handleClick('/orders/cancelled')} id="order-li">
                      <Link to="/orders/cancelled" className="has-arrow " style={{ color: (isOrderPage() ? "white" : "") }} id="order-b">
                        <i style={{ color: (isOrderPage() ? "white" : "") }}><ArchiveBox size={20} /></i>
                        <span style={{ color: (isOrderPage() ? "white" : "") }}>{props.t("Cancelled Orders")}</span>
                      </Link>
                      <ul className="sub-menu" id="order-ul">
                        <SiderBarStoreDropDown props={props} />
                      </ul>
                    </li>

                    <li className={document.title == `Completed Orders | ${config.APP_DETAILS.APP_NAME}` ? "mm-active" : ""}>
                      <span className={document.title == `Completed Orders | ${config.APP_DETAILS.APP_NAME}` ? "active-page" : ""}></span>
                      <a onClick={(e) => {
                        e.preventDefault();
                        if (localStorage.getItem("order-filter") == "Unassigned") {
                          localStorage.setItem("order-filter", "FedEx");
                        }

                        props.history.push('/orders/completed');
                      }} >
                        <i><ClipboardTick size={20} /></i>
                        <span>{props.t("Completed Orders")}</span>
                      </a>
                    </li>
                    <li>
                      <span className={document.title == `Orders Tracking | ${config.APP_DETAILS.APP_NAME}` ? "active-page" : ""}></span>
                      <Link to="/orders/tracking" >
                        <i><Location size={20} /></i>
                        <span>{props.t("Orders Tracking")}</span>
                      </Link>
                    </li>
                    <li>
                      <span className={document.title == `Tickets | ${config.APP_DETAILS.APP_NAME}` ? "active-page" : ""}></span>
                      <Link to="/my/tickets" >
                        <i><Save2 size={20} /></i>
                        <span>{props.t("Tickets")}</span>
                      </Link>
                    </li>

                    <li>
                      <span className={document.title == `Wallet History | ${config.APP_DETAILS.APP_NAME}` ? "active-page" : ""}></span>
                      <Link to="/wallet-history" >
                        <i><EmptyWalletTime size={20} /></i>
                        <span>{props.t("Wallet History")}</span>
                      </Link>
                    </li>

                    {/*{JSON.parse(localStorage.getItem("authUser")).shopify == "1"?*/}
                    {/*  <>*/}
                    {/*  <li>*/}
                    {/*    <Link to="/#" className="has-arrow ">*/}
                    {/*      <i className="bx bxl-shopify"></i>*/}
                    {/*      <span>{props.t("Shopify")}</span>*/}
                    {/*    </Link>*/}
                    {/*    <ul className="sub-menu">*/}
                    {/*      /!*<li>*!/*/}
                    {/*      /!*  <Link to="/shopify/stores" >*!/*/}
                    {/*      /!*    <i className="bx bx-store"></i>*!/*/}
                    {/*      /!*    <span>{props.t("Stores")}</span>*!/*/}
                    {/*      /!*  </Link>*!/*/}
                    {/*      /!*</li>*!/*/}
                    {/*      <li>*/}
                    {/*        <Link to="/shopify/awaiting-shipments" >*/}
                    {/*          <i className="bx bxs-box"></i>*/}
                    {/*          <span>{props.t("Awaiting Shipments")}</span>*/}
                    {/*        </Link>*/}
                    {/*      </li>*/}
                    {/*      /!*<li>*!/*/}
                    {/*      /!*  <Link to="/shopify/other/orders" >*!/*/}
                    {/*      /!*    <i className="bx bx-list-ul"></i>*!/*/}
                    {/*      /!*    <span>{props.t("Other Shopify Orders")}</span>*!/*/}
                    {/*      /!*  </Link>*!/*/}
                    {/*      /!*</li>*!/*/}
                    {/*    </ul>*/}
                    {/*  </li></>:""}*/}
                    {/*{JSON.parse(localStorage.getItem("authUser")).ebay == "1"?*/}
                    {/*  <>*/}
                    {/*    <li>*/}
                    {/*      <Link to="/#" className="has-arrow">*/}
                    {/*        <i className="bx bxl-ebay"></i>*/}
                    {/*        <span>{props.t("eBay")}</span>*/}
                    {/*      </Link>*/}
                    {/*      <ul className="sub-menu">*/}
                    {/*        /!*<li>*!/*/}
                    {/*        /!*  <Link to="/ebay/stores">*!/*/}
                    {/*        /!*    <i className="bx bx-store"></i>*!/*/}
                    {/*        /!*    <span>{props.t("Stores")}</span>*!/*/}
                    {/*        /!*  </Link>*!/*/}
                    {/*        /!*</li>*!/*/}
                    {/*        <li>*/}
                    {/*          <Link to="/ebay/awaiting-shipments">*/}
                    {/*            <i className="bx bxs-box"></i>*/}
                    {/*            <span>{props.t("Awaiting Shipments")}</span>*/}
                    {/*          </Link>*/}
                    {/*        </li>*/}
                    {/*      </ul>*/}
                    {/*    </li>*/}
                    {/*    </>:""*/}
                    {/*}*/}
                    <li>
                      <Link to="/#" className="has-arrow ">
                        <i><Bootstrap size={20} /></i>
                        <span>{props.t("API")}</span>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <span className={document.title == `API Keys | ${config.APP_DETAILS.APP_NAME}` ? "active-page-submenu" : ""}></span>
                          <Link to="/api/keys" >
                            {/*<i className="bx bxs-key"></i>*/}
                            <span>{props.t("Keys")}</span>
                          </Link>
                        </li>
                        <li>
                          {/*<i className="bx bxs-key"></i>*/}
                          {/* <span className={document.title == `API Webhook | ${config.APP_DETAILS.APP_NAME}` ? "active-page-submenu" : ""}></span>
                          <Link to="/api/webhook" >
                            <span>{props.t("Webhook")}</span>
                          </Link> */}
                        </li>
                        <li>
                          <span className={document.title == "API Documentation | ${config.APP_DETAILS.APP_NAME}" ? "active-page-submenu" : ""}></span>
                          <Link to="/api/documentation" >
                            {/*<i className="bx bxs-file-doc"></i>*/}
                            <span>{props.t("Documentation")}</span>
                          </Link>
                        </li>
                        {/*<li>*/}
                        {/*  <Link to="/api/reports" >*/}
                        {/*    <i className="bx bx-bar-chart"></i>*/}
                        {/*    <span>{props.t("Reports")}</span>*/}
                        {/*  </Link>*/}
                        {/*</li>*/}
                      </ul>
                    </li>
                    {JSON.parse(localStorage.getItem("authUser")).shopify == "1" || JSON.parse(localStorage.getItem("authUser")).ebay == "1" || JSON.parse(localStorage.getItem("authUser")).walmart == "1" || JSON.parse(localStorage.getItem("authUser")).zenventory == "1" || JSON.parse(localStorage.getItem("authUser")).sellercloud == "1" || JSON.parse(localStorage.getItem("authUser")).shipstation == "1" ?
                      <li>
                        <span className={document.title == `Settings | ${config.APP_DETAILS.APP_NAME}` ? "active-page" : ""}></span>
                        <Link to="/settings" >
                          <i><Setting size={20} /></i>
                          <span>{props.t("Settings")}</span>
                        </Link>
                      </li> : ""}

                    <li>
                      <Link to="/address-validation" >
                        <i><LocationTick size={20} /></i>
                        <span>{props.t("Address Validation")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/#" className="has-arrow">
                        <i className="bx bx-clipboard"></i>
                        <span>{props.t("Reports")}</span>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/daily-report" >
                            <span>{props.t("Daily Report")}</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/balance-report" >
                            <span>{props.t("Balance Report")}</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/tracking-cost-report" >
                            <span>{props.t("Tracking Cost Report")}</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/referral" >
                        <i><Profile2User size={20} /></i>
                        <span>{props.t("Referral")}</span>
                      </Link>
                    </li>
                    {/* <li>
                      <span className={document.title == `User | ${config.APP_DETAILS.APP_NAME}` ? "active-page" : ""}></span>
                      <Link to="/my/users" >
                        <i><User size={20} /></i>
                        <span>{props.t("Users")}</span>
                      </Link>
                    </li> */}
                  </>
                  : (JSON.parse(localStorage.getItem("authUser")).role_id === 3 ?
                    <>
                      <li>
                        <Link to="/blog/categories" >
                          <i className="bx bx-git-branch"></i>
                          <span>{props.t("Blog Categories")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/blogs" >
                          <i className="bx bxs-book"></i>
                          <span>{props.t("Blogs")}</span>
                        </Link>
                      </li>
                    </> :
                    JSON.parse(localStorage.getItem("authUser")).role_id === 4 && (
                      <>
                        <li>
                          <span className={document.title == `Dashboard | ${config.APP_DETAILS.APP_NAME}` ? "active-page" : ""}></span>
                          <Link to="/dashboard" >
                            <i><Home size={20} /></i>
                            <span>{props.t("Dashboard")}</span>
                          </Link>
                        </li>
                        <li>
                          <span className={document.title == `Wallet History | ${config.APP_DETAILS.APP_NAME}` ? "active-page" : ""}></span>
                          <Link to="/wallet-history" >
                            <i><EmptyWalletTime size={20} /></i>
                            <span>{props.t("Wallet History")}</span>
                          </Link>
                        </li>
                        <li id="order-li">
                          <Link to="/#" className="has-arrow " style={{ color: (isOrderPage() ? "white" : "") }} id="order-a">
                            <i style={{ color: (isOrderPage() ? "white" : "") }}><ArchiveBox size={20} /></i>
                            <span style={{ color: (isOrderPage() ? "white" : "") }}>{props.t("Orders")}</span>
                          </Link>
                          <ul className="sub-menu" id="order-ul">
                            {stores.length > 0 ? (stores.filter(el => el.id == localStorage.getItem("store"))[0]?.store_type == "shopify" ?
                              <li className={document.title == `On Hold Orders | ${config.APP_DETAILS.APP_NAME}` ? "mm-active" : ""}>
                                <span className={document.title == `On Hold Orders | ${config.APP_DETAILS.APP_NAME}` ? "active-page-submenu" : ""}></span>
                                <Link to="/orders/on-hold">
                                  <span>{props.t("On Hold")}</span>
                                </Link>
                              </li> : "") : ""}
                            <li className={document.title == `Awaiting Shipment | ${config.APP_DETAILS.APP_NAME}` ? "mm-active" : ""}>
                              <span className={document.title == `Awaiting Shipment | ${config.APP_DETAILS.APP_NAME}` ? "active-page-submenu" : ""}></span>
                              <a onClick={(e) => {
                                e.preventDefault();
                                if (localStorage.getItem("order-filter") == null && localStorage.getItem("store") != "0") {
                                  localStorage.setItem("order-filter", "Unassigned");
                                  props.history.push('/orders/awaiting-shipment');
                                }
                                else {

                                  get('/admin/get-carriers', { headers }).then((response) => {
                                    // console.log("carriers", response.data);
                                    setCarriers(response.data);
                                    if (response.data.length > 0) {
                                      if (localStorage.getItem("order-filter") == null) {
                                        // console.log("localStorage.setItem order-filter",localStorage.getItem("order-filter"))
                                        // console.log("localStorage.setItem order-filter",response.data)
                                        // console.log("localStorage.setItem order-filter",response.data.filter(el => el.is_active == 1)[0].name)
                                        localStorage.setItem("order-filter", response.data.filter(el => el.is_active == 1)[0].name);
                                      }
                                      else if (localStorage.getItem("order-filter") == "Unassigned") {
                                        localStorage.setItem("order-filter", "Unassigned");
                                      }
                                      else if (response.data.filter(el => el.name == localStorage.getItem("order-filter"))[0].is_active == 0) {
                                        // console.log("localStorage.setItem order-filter",localStorage.getItem("order-filter"))
                                        // console.log("localStorage.setItem order-filter",response.data)
                                        // console.log("localStorage.setItem order-filter",response.data.filter(el => el.is_active == 1)[0].name)
                                        localStorage.setItem("order-filter", response.data.filter(el => el.is_active == 1)[0].name);
                                      }
                                    }

                                    props.history.push('/orders/awaiting-shipment');
                                  });
                                }
                              }}>
                                <span>{props.t("Awaiting Shipment")}</span>
                              </a>
                            </li>
                            <li className={document.title == `Cancelled Orders | ${config.APP_DETAILS.APP_NAME}` ? "mm-active" : ""}>
                              <span className={document.title == `Cancelled Orders | ${config.APP_DETAILS.APP_NAME}` ? "active-page-submenu" : ""}></span>
                              <a onClick={(e) => {
                                e.preventDefault();
                                if (localStorage.getItem("order-filter") == "Unassigned") {
                                  localStorage.setItem("order-filter", "FedEx");
                                }

                                props.history.push('/orders/cancelled');
                              }} >
                                <span>{props.t("Cancelled")}</span>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className={document.title == `Completed Orders | ${config.APP_DETAILS.APP_NAME}` ? "mm-active" : ""}>
                          <span className={document.title == `Completed Orders | ${config.APP_DETAILS.APP_NAME}` ? "active-page" : ""}></span>
                          <a onClick={(e) => {
                            e.preventDefault();
                            if (localStorage.getItem("order-filter") == "Unassigned") {
                              localStorage.setItem("order-filter", "FedEx");
                            }

                            props.history.push('/orders/completed');
                          }} >
                            <i><ClipboardTick size={20} /></i>
                            <span>{props.t("Completed Orders")}</span>
                          </a>
                        </li>
                        <li>
                          <span className={document.title == `Orders Tracking | ${config.APP_DETAILS.APP_NAME}` ? "active-page" : ""}></span>
                          <Link to="/orders/tracking" >
                            <i><Location size={20} /></i>
                            <span>{props.t("Orders Tracking")}</span>
                          </Link>
                        </li>
                        <li>
                          <span className={document.title == `Tickets | ${config.APP_DETAILS.APP_NAME}` ? "active-page" : ""}></span>
                          <Link to="/my/tickets" >
                            <i><Save2 size={20} /></i>
                            <span>{props.t("Tickets")}</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/#" className="has-arrow ">
                            <i><Bootstrap size={20} /></i>
                            <span>{props.t("API")}</span>
                          </Link>
                          <ul className="sub-menu">
                            <li>
                              <span className={document.title == `API Keys | ${config.APP_DETAILS.APP_NAME}` ? "active-page-submenu" : ""}></span>
                              <Link to="/api/keys" >
                                {/*<i className="bx bxs-key"></i>*/}
                                <span>{props.t("Keys")}</span>
                              </Link>
                            </li>
                            <li>
                              <span className={document.title == `API Webhook | ${config.APP_DETAILS.APP_NAME}` ? "active-page-submenu" : ""}></span>
                              <Link to="/api/webhook" >
                                {/*<i className="bx bxs-key"></i>*/}
                                <span>{props.t("Webhook")}</span>
                              </Link>
                            </li>
                            <li>
                              <span className={document.title == `API Documentation | ${config.APP_DETAILS.APP_NAME}` ? "active-page-submenu" : ""}></span>
                              <Link to="/api/documentation" >
                                {/*<i className="bx bxs-file-doc"></i>*/}
                                <span>{props.t("Documentation")}</span>
                              </Link>
                            </li>
                            {/*<li>*/}
                            {/*  <Link to="/api/reports" >*/}
                            {/*    <i className="bx bx-bar-chart"></i>*/}
                            {/*    <span>{props.t("Reports")}</span>*/}
                            {/*  </Link>*/}
                            {/*</li>*/}
                          </ul>
                        </li>
                        {JSON.parse(localStorage.getItem("authUser")).shopify == "1" || JSON.parse(localStorage.getItem("authUser")).ebay == "1" || JSON.parse(localStorage.getItem("authUser")).walmart == "1" || JSON.parse(localStorage.getItem("authUser")).zenventory == "1" || JSON.parse(localStorage.getItem("authUser")).sellercloud == "1" || JSON.parse(localStorage.getItem("authUser")).shipstation == "1" ?
                          <li>
                            <span className={document.title == `Settings | ${config.APP_DETAILS.APP_NAME}` ? "active-page" : ""}></span>
                            <Link to="/settings" >
                              <i><Setting size={20} /></i>
                              <span>{props.t("Settings")}</span>
                            </Link>
                          </li> : ""}

                        <li>
                          <Link to="/address-validation" >
                            <i><LocationTick size={20} /></i>
                            <span>{props.t("Address Validation")}</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/#" className="has-arrow">
                            <i className="bx bx-clipboard"></i>
                            <span>{props.t("Reports")}</span>
                          </Link>
                          <ul className="sub-menu">
                            <li>
                              <Link to="/daily-report" >
                                <span>{props.t("Daily Report")}</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/balance-report" >
                                <span>{props.t("Balance Report")}</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/tracking-cost-report" >
                                <span>{props.t("Tracking Cost Report")}</span>
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="/referral" >
                            <i><Profile2User size={20} /></i>
                            <span>{props.t("Referral")}</span>
                          </Link>
                        </li>
                        {/* <li>
                          <span className={document.title == `User | ${config.APP_DETAILS.APP_NAME}` ? "active-page" : ""}></span>
                          <Link to="/my/users" >
                            <i><User size={20} /></i>
                            <span>{props.t("User")}</span>
                          </Link>
                        </li> */}
                      </>
                    )
                  )
                )
            }
            {/* <div style={{ position: "fixed", bottom: "0px", left: "0px", padding: "5px 0px 10px 23px", fontSize: "1.45rem", backgroundColor: "#0f0f0f", width: (show ? "250px" : "50px") }}> */}
            <div style={{ position: "fixed", bottom: "0px", left: "0px", padding: "5px 0px 10px 23px", fontSize: "1.45rem", backgroundColor: "#000", width: (show ? "250px" : "50px") }}>
              <Link to="/logout">
                <i><LoginCurve color={"#FF6969"} size={20} /></i>
                {show ? <span className={"text-danger spantohide"} style={{ fontSize: "13px", marginLeft: "12px" }}>{props.t("Logout")}</span> : ""}
                {/*{document.body.classList.contains('vertical-collpsed')?<span className={"text-danger spantohide"} style={{fontSize:"13px",marginLeft:"6px"}}>{props.t("Logout")}</span>:}*/}
              </Link>
            </div>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
